<template>
  <v-container>
    <h2>Selbstauskünfte</h2>
    <v-divider class="mb-5"></v-divider>
    <disclosure-form></disclosure-form>
  </v-container>
</template>

<script>
import DisclosureForm from '@/components/disclosure/Form'
export default {
  components: { DisclosureForm }
}
</script>
