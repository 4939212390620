'use strict';

import axios from 'axios';
import Auth from '@aws-amplify/auth';

const API = {
    async submitData(data) {
        let token = '';
        await Auth.currentAuthenticatedUser().then((data) => {
            token = data.getSignInUserSession().getIdToken().getJwtToken();
        });
        let body = getBody(data);
        let config = {
            method: 'post',
            url: process.env.VUE_APP_API_DOMAIN + '/v1',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: body
        };
        return axios(config);
    }
};

function getBody(data) {
    return JSON.stringify({
        query: 'mutation {\n' +
            ' submitPrivateFormData(\n' +
            '    data: {\n' +
            '      Person: {\n' +
            '        Name: {\n' +
            '          first: "' + data.person.name.first + '"\n' +
            '          last: "' + data.person.name.last + '"\n' +
            '          formerFirst: "' + data.person.name.formerFirst + '"\n' +
            '          formerLast: "' + data.person.name.formerLast + '"\n' +
            '        }\n' +
            ((data.person.date.birth !== '') ? '        Date: {\n' + '          birth: "' + data.person.date.birth + '"\n' + '        }\n' : "" ) +
            '        Title: "' + data.person.title + '"\n' +
            '      }\n' +
            '      Address: {\n' +
            '        Street: "' + data.address.street + '"\n' +
            '        Housenumber: "' + data.address.housenumber + '"\n' +
            '        HousenumberSupplement: "' + data.address.housenumberSupplement + '"\n' +
            '        Postcode: "' + data.address.postcode + '"\n' +
            '        City: "' + data.address.city + '"\n' +
            '        Country: "' + data.address.country.toUpperCase() + '"\n' +
            '        AddressSupplement: "' + data.address.addressSupplement + '"\n' +
            '        CareOf: "' + data.address.careOf + '"\n' +
            '      }\n' +
            '      FormerAddresses: ' + getFormerAddresses(data.formerAddresses) + '\n' +
            '      PrivacyPolicyChecked: true\n' +
            ((data.email !== "") ? '      Email: "' + data.email + '"\n' : "" ) +
            '      Telephone: "' + data.telephone + '"\n' +
            '      ReferenceNumber: "' + data.referenceNumber + '"\n' +
            '      Source: "' + data.source + '"\n' +
            '      Comment: "' + data.comment + '"\n' +
            '      DeliveryType: "' + data.deliveryType + '"\n' +
            '      ReceivedDate: "' + data.receivedDate + '"\n' +
            '    }\n' +
            '  )\n' +
            '}'
    });
}

function getFormerAddresses(data) {
    let formerAddresses = '[';
    Array.from(data).forEach(address => {
        formerAddresses += '{\n' +
            '        Street: "' + address['street'].value + '"\n' +
            '        Housenumber: "' + address['housenumber'].value + '"\n' +
            '        HousenumberSupplement: "' + address['housenumberSupplement'].value + '"\n' +
            '        Postcode: "' + address['postcode'].value + '"\n' +
            '        City: "' + address['city'].value + '"\n' +
            '        Country: "' + address['country'].value.toUpperCase() + '"\n' +
            '      }';
    });

    formerAddresses += ']';

    return formerAddresses;
}

export default API;
