'use strict';

function isValidDate(dateString) {
    let dateParts = dateString.split('.')
    let dateObj = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`)

    return dateObj.getUTCDate() === parseInt(dateParts[0]) &&
        (dateObj.getUTCMonth() + 1) === parseInt(dateParts[1]) &&
        dateObj.getUTCFullYear() === parseInt(dateParts[2]);

}

const InputRules = {
    required(msg = 'Bitte füllen Sie dieses Pflichtfeld aus.') {
        return v => !!v || msg;
    },
    stringLengthMin(length = 1) {
        return v => v.length >= length || 'Es müssen mindestens ' + length + ' Zeichen sein';
    },
    stringLengthMax(length = 100) {
        return v => v.length <= length || 'Es dürfen maximal ' + length + ' Zeichen sein';
    },
    emailValid(msg = 'E-Mail-Adresse ist ungültig') {
        return v => /.+@.+\.[a-zA-Z]{2,}$/.test(v) || msg;
    },
    emailValidOrEmpty(msg = 'E-Mail-Adresse ist ungültig') {
        return v => /(^$|.+@.+\.[a-zA-Z]{2,})/.test(v) || msg;
    },
    dateValid(msg = 'Datumsformat ist ungültig') {
        return v => isValidDate(v) || msg;
    },
    dateValidOrEmpty(msg = 'Datumsformat ist ungültig') {
        return v => (v === ''  || isValidDate(v)) || msg;
    }
};

export default InputRules;
