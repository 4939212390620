<template>
  <v-form
      ref="form"
      v-model="valid"
      v-bind:class="{ preview: statePreview }"
  >
    <v-row dense>
      <v-col>
        <v-menu
            v-model="dateReceived.menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            ref="dateReceivedMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateReceived.value"
                :label="dateReceived.label"
                :rules="dateReceived.rules"
                :disabled="statePreview"
                v-bind="attrs"
                v-on="on"
                placeholder="dd.mm.yyyy"
                ref="dateReceived"
                outlined
                dense
                required
                @change="saveDateReceivedInput"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="dateReceived.date"
              :max="new Date().toISOString().substr(0, 10)"
              min="2020-01-01"
              required
              @change="saveDateReceived"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-select
            v-model="source.value"
            :items="source.list"
            :rules="source.rules"
            :label="source.label"
            :disabled="statePreview"
            item-value="key"
            item-text="name"
            outlined
            dense
            required
        ></v-select>
      </v-col>
      <v-col>
        <v-select
            v-model="deliveryType.value"
            :items="deliveryType.list"
            :rules="deliveryType.rules"
            :label="deliveryType.label"
            :disabled="statePreview"
            item-value="key"
            item-text="name"
            outlined
            dense
            required
        ></v-select>
      </v-col>
    </v-row>

    <v-divider class="mb-5"></v-divider>

    <h3 class="mb-3">Persönliche Daten</h3>

    <v-row dense>
      <v-col cols="6">
        <v-select
            v-model="title.value"
            :items="title.list"
            :rules="title.rules"
            :label="title.label"
            :disabled="statePreview"
            item-value="key"
            item-text="name"
            outlined
            dense
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <v-text-field
            v-model="firstname.value"
            :rules="firstname.rules"
            :label="firstname.label"
            :disabled="statePreview"
            outlined
            dense
            required
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            v-model="lastname.value"
            :rules="lastname.rules"
            :label="lastname.label"
            :disabled="statePreview"
            outlined
            dense
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <v-text-field
            v-model="formerFirstname.value"
            :rules="formerFirstname.rules"
            :label="formerFirstname.label"
            :disabled="statePreview"
            outlined
            dense
            required
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            v-model="formerLastname.value"
            :rules="formerLastname.rules"
            :label="formerLastname.label"
            :disabled="statePreview"
            outlined
            dense
            required
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-menu
            v-model="dateOfBirth.menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            ref="dateOfBirthMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateOfBirth.value"
                :label="dateOfBirth.label"
                :rules="dateOfBirth.rules"
                :disabled="statePreview"
                v-bind="attrs"
                v-on="on"
                ref="dateOfBirth"
                placeholder="dd.mm.yyyy"
                outlined
                dense
                @change="saveDateOfBirthInput"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="dateOfBirth.date"
              :max="new Date().toISOString().substr(0, 10)"
              min="1900-01-01"
              @change="saveDateOfBirth"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider class="mb-5"></v-divider>

    <h3 class="mb-3">Aktuelle Adresse</h3>

    <v-row dense>
      <v-col cols="8">
        <v-text-field
            v-model="street.value"
            :rules="street.rules"
            :label="street.label"
            :disabled="statePreview"
            outlined
            dense
            required
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
            v-model="housenumber.value"
            :rules="housenumber.rules"
            :label="housenumber.label"
            :disabled="statePreview"
            outlined
            dense
            required
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
            v-model="housenumberSupplement.value"
            :rules="housenumberSupplement.rules"
            :label="housenumberSupplement.label"
            :disabled="statePreview"
            outlined
            dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <v-text-field
            v-model="addressSupplement.value"
            :rules="addressSupplement.rules"
            :label="addressSupplement.label"
            :disabled="statePreview"
            outlined
            dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            v-model="careOf.value"
            :rules="careOf.rules"
            :label="careOf.label"
            :disabled="statePreview"
            outlined
            dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <v-text-field
            v-model="postcode.value"
            :rules="postcode.rules"
            :label="postcode.label"
            :disabled="statePreview"
            outlined
            dense
            required
        ></v-text-field>
      </v-col>
      <v-col cols="5">
        <v-text-field
            v-model="city.value"
            :rules="city.rules"
            :label="city.label"
            :disabled="statePreview"
            outlined
            dense
            required
        ></v-text-field>
      </v-col>
      <v-col cols="5">
        <v-autocomplete
            v-model="country.value"
            :label="country.label"
            :items="country.list"
            :disabled="statePreview"
            item-value="alpha3"
            item-text="name"
            outlined
            dense
        ></v-autocomplete>
      </v-col>
    </v-row>

    <div v-for="(formerAddress, counter) in formerAddresses" v-bind:key="counter">
      <v-row dense>
        <v-col>
          <h3>{{ counter + 1 }}. Voranschrift</h3>
        </v-col>
        <v-col class="text-right">
          <v-btn icon v-on:click="deleteFormerAddress(counter)" :disabled="statePreview">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="8">
          <v-text-field
              v-model="formerAddress.street.value"
              :rules="street.rules"
              :label="street.label"
              :disabled="statePreview"
              outlined
              dense
              required
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
              v-model="formerAddress.housenumber.value"
              :rules="housenumber.rules"
              :label="housenumber.label"
              :disabled="statePreview"
              outlined
              dense
              required
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
              v-model="formerAddress.housenumberSupplement.value"
              :rules="housenumberSupplement.rules"
              :label="housenumberSupplement.label"
              :disabled="statePreview"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="2">
          <v-text-field
              v-model="formerAddress.postcode.value"
              :rules="postcode.rules"
              :label="postcode.label"
              :disabled="statePreview"
              outlined
              dense
              required
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-text-field
              v-model="formerAddress.city.value"
              :rules="city.rules"
              :label="city.label"
              :disabled="statePreview"
              outlined
              dense
              required
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-autocomplete
              v-model="formerAddress.country.value"
              :label="country.label"
              :items="country.list"
              :disabled="statePreview"
              item-value="alpha3"
              item-text="name"
              outlined
              dense
          ></v-autocomplete>
        </v-col>
      </v-row>
    </div>
    <v-btn
        color="info"
        v-if="formerAddresses.length < formerAddressesMax"
        :disabled="statePreview"
        @click="addFormerAddress">
      Voranschrift hinzufügen
    </v-btn>

    <v-divider class="mb-5 mt-7"></v-divider>

    <h3 class="mb-3">Laufende Vorgänge</h3>

    <v-row dense>
      <v-col>
        <v-text-field
            v-model="referecenumber.value"
            :rules="referecenumber.rules"
            :label="referecenumber.label"
            :disabled="statePreview"
            outlined
            dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <v-text-field
            v-model="email.value"
            :rules="email.rules"
            :label="email.label"
            :disabled="statePreview"
            outlined
            dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            v-model="telephone.value"
            :rules="telephone.rules"
            :label="telephone.label"
            :disabled="statePreview"
            outlined
            dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-textarea
            v-model="comment.value"
            :rules="comment.rules"
            :label="comment.label"
            :disabled="statePreview"
            outlined
            dense
        ></v-textarea>
      </v-col>
    </v-row>

    <v-container>
      <v-row v-if="!statePreview" class="justify-space-between">
        <v-btn @click="cancel" class="mr-auto">Abbruch</v-btn>
        <v-btn color="info" @click="validate" v-if="!valid">Validieren</v-btn>
        <v-btn color="success" @click="toggleStatePreview" v-if="valid">Weiter</v-btn>
      </v-row>
      <v-row v-if="statePreview" class="justify-space-between">
        <v-btn @click="toggleStatePreview" class="mr-auto">Zurück</v-btn>
        <v-btn color="success" @click="send" v-if="valid">Speichern</v-btn>
      </v-row>
    </v-container>

    <v-snackbar
        v-model="snackbar"
        top
        centered
        app
        :color=snackbarColor
        :timeout=snackbarTimeout
    >
      {{ snackbarMessage }}
    </v-snackbar>

    <v-overlay :value="overlayPreview" :absolute="overlayAbsolute">
      <h1>Vorschau - Vorschau - Vorschau</h1>
    </v-overlay>
  </v-form>
</template>

<script>
import API from '@/api/index';
import countryData from '@/components/disclosure/countries.json';
import InputRules from '@/components/r24-input/InputRules';

function initializeData (vm) {
  let data = {
    valid: false,
    countryData: countryData,

    dateReceived: {
      menu: false,
      value: vm.formatDate(new Date().toISOString().substr(0, 10)),
      date: new Date().toISOString().substr(0, 10),
      label: 'Eingangsdatum',
      required: true,
      rules: [
        InputRules.dateValid(),
      ],
    },
    source: {
      value: '',
      label: 'SAK Herkunft',
      list: [
        { key: 'post', name: 'Post' },
        { key: 'fax', name: 'Fax' },
        { key: 'selbstauskunft.net', name: 'Selbstauskunft.net' },
        { key: 'email', name: 'E-Mail' },
        { key: 'regisformular', name: 'Regis-Formular' },
      ],
      required: true,
      rules: [],
    },
    deliveryType: {
      value: { key: 'post', name: 'Post' },
      label: 'SAK Auskunft per',
      list: [
        { key: 'post', name: 'Post' },
      ],
      required: true,
      rules: [],
    },
    title: {
      value: '',
      label: 'Anrede',
      list: [
        { key: 'herr', name: 'Herr' },
        { key: 'frau', name: 'Frau' },
        { key: 'divers', name: 'Divers' },
      ],
      rules: [],
    },
    firstname: {
      value: '',
      label: 'Vornamen',
      required: true,
      rules: [
        InputRules.stringLengthMax(100),
      ],
    },
    lastname: {
      value: '',
      label: 'Nachnamen',
      required: true,
      rules: [
        InputRules.stringLengthMax(50),
      ],
    },
    formerFirstname: {
      value: '',
      label: 'Frühere Vornamen',
      rules: [
        InputRules.stringLengthMax(100),
      ],
    },
    formerLastname: {
      value: '',
      label: 'Frühere Nachnamen / Geburtsname',
      rules: [
        InputRules.stringLengthMax(50),
      ],
    },
    dateOfBirth: {
      menu: false,
      value: '',
      date: undefined,
      label: 'Geburtsdatum',
      rules: [
        InputRules.dateValidOrEmpty(),
      ],
    },
    street: {
      value: '',
      label: 'Straße',
      required: true,
      rules: [
        InputRules.stringLengthMax(100),
      ],
    },
    housenumber: {
      value: '',
      label: 'Hausnummer',
      required: true,
      rules: [
        InputRules.stringLengthMax(10),
      ],
    },
    housenumberSupplement: {
      value: '',
      label: 'Hausnummernzusatz',
      rules: [
        InputRules.stringLengthMax(5),
      ],
    },
    addressSupplement: {
      value: '',
      label: 'Adresszusatz',
      rules: [
        InputRules.stringLengthMax(50),
      ],
    },
    careOf: {
      value: '',
      label: 'Wohnungsgeber',
      rules: [
        InputRules.stringLengthMax(100),
      ],
    },
    postcode: {
      value: '',
      label: 'PLZ',
      required: true,
      rules: [
        InputRules.stringLengthMax(5),
      ],
    },
    city: {
      value: '',
      label: 'Ort',
      required: true,
      rules: [
        InputRules.stringLengthMax(50),
      ],
    },
    country: {
      value: 'deu',
      label: 'Land',
      list: countryData,
      rules: [],
    },

    formerAddresses: [],
    formerAddressesMax: 5,

    referecenumber: {
      value: '',
      label: 'Aktenzeichen',
      rules: [
        InputRules.stringLengthMax(200),
      ],
    },
    email: {
      value: '',
      label: 'E-Mail-Adresse',
      rules: [
        InputRules.emailValidOrEmpty(),
        InputRules.stringLengthMax(100),
      ],
    },
    telephone: {
      value: '',
      label: 'Telefonnummer',
      rules: [
        InputRules.stringLengthMax(20),
      ],
    },

    comment: {
      value: '',
      label: 'Interne Anmerkungen',
      rules: [
        InputRules.stringLengthMax(200),
      ],
    },

    snackbar: false,
    snackbarMessage: '',
    snackbarColor: 'success',
    snackbarTimeout: 5000,

    statePreview: false,
    overlayPreview: false,
    overlayAbsolute: true,
  };

  Object.keys(data).forEach((value) => {
    if (data[value].required === true) {
      data[value].label += '*';
      data[value].rules = [
        InputRules.required(),
        ...data[value].rules,
      ];
    }
  });
  return data;
}

export default {
  name: 'DisclosureForm',

  data: (vm) => (initializeData(vm)),
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    overlayPreview (val) {
      val && setTimeout(() => {
        this.overlayPreview = false;
      }, 1000);
    },
  },
  methods: {
    // date formatting
    formatDate (date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    formatInputDateToPicker (date) {
      if (date) {
        const [day, month, year] = date.split('.');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }

      return null;
    },
    // save DateReceived
    saveDateReceivedInput (date) {
      if (this.$refs.dateReceived.valid) {
        this.saveDateReceived(this.formatInputDateToPicker(date));
      }
    },
    saveDateReceived (date) {
      if (!date) {
        return;
      }
      this.dateReceived.value = this.formatDate(date);
      this.dateReceived.date = date;
      this.$refs.dateReceivedMenu.save(date);
    },
    // save DateOfBirth
    saveDateOfBirthInput (date) {
      if (this.$refs.dateOfBirth.valid) {
        this.saveDateOfBirth(this.formatInputDateToPicker(date));
      }
    },
    saveDateOfBirth (date) {
      if (!date) {
        this.dateOfBirth.value = '';
        this.dateOfBirth.date = undefined;
        return;
      }
      this.dateOfBirth.value = this.formatDate(date);
      this.dateOfBirth.date = date;
      this.$refs.dateOfBirthMenu.save(date);
    },
    addFormerAddress () {
      this.formerAddresses.push({
        street: { value: '' },
        housenumber: { value: '' },
        housenumberSupplement: { value: '' },
        postcode: { value: '' },
        city: { value: '' },
        country: { value: 'deu' },
      });
    },
    deleteFormerAddress (counter) {
      this.formerAddresses.splice(counter, 1);
    },
    validate () {
      this.$refs.form.validate();
    },
    send () {
      let data = {
        person: {
          title: this.title.value,
          name: {
            first: this.firstname.value,
            last: this.lastname.value,
            formerFirst: this.formerFirstname.value,
            formerLast: this.formerLastname.value,
          },
          date: {
            birth: '',
          },
        },
        address: {
          street: this.street.value,
          housenumber: this.housenumber.value,
          housenumberSupplement: this.housenumberSupplement.value,
          postcode: this.postcode.value,
          city: this.city.value,
          country: this.country.value,
          addressSupplement: this.addressSupplement.value,
          careOf: this.careOf.value,
        },
        email: '',
        formerAddresses: this.formerAddresses,
        telephone: this.telephone.value,
        referenceNumber: this.referecenumber.value,
        source: this.source.value,
        comment: escape(this.comment.value),
        deliveryType: this.deliveryType.value.key,
        receivedDate: this.dateReceived.date,
      };

      if (typeof this.email.value !== 'undefined') {
        data.email = this.email.value;
      }

      if (typeof this.dateOfBirth.date !== 'undefined') {
        data.person.date.birth = this.dateOfBirth.date;
      }

      API.submitData(data)
          .then((response) => {
            if (Object.prototype.hasOwnProperty.call(response.data, 'errors')) {
              console.log(response.data.errors);
              this.snackbarTimeout = 15000;
              this.snackbarColor = 'error';
              this.snackbarMessage = '400 - something went wrong';
              this.snackbar = true;
              this.snackbarMessage += ' => ' + response.data.errors[0].message;
            } else {
              Object.assign(this.$data, initializeData(this));
              this.$refs.form.resetValidation();
              this.snackbarColor = 'success';
              this.snackbarMessage = 'saved successfully';
              this.snackbar = true;
              window.scrollTo(0, 0);
            }
          })
          .catch((error) => {
            console.log(error);
            this.snackbarColor = 'error';
            this.snackbarMessage = '400 - something went wrong';
            this.snackbar = true;
          });
    },
    cancel () {
      return this.$router.push({ name: 'disclosure-list' });
    },
    toggleStatePreview () {
      this.statePreview = !this.statePreview;
      if (this.statePreview) {
        this.overlayPreview = !this.overlayPreview;
      }
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
.preview {
  .v-input__slot fieldset {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .theme--light.v-input input,
  .theme--light.v-input textarea,
  .theme--light.v-select .v-select__selection--disabled {
    color: rgba(0, 0, 0, 1);
  }
}

.v-overlay--absolute {
  padding: 5rem;
}
</style>
